import React from 'react';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
import { Container } from './styles';
import Menu from '~/components/Menu';
import Cruelty from '~/assets/cruelty-free.png';
import Tiktok from '~/assets/tiktok.svg';
import Amazon from '~/assets/AMAZON_BRANCO.svg';
import BotaoComprar from '~/components/BotaoComprarTop';
import BotaoComprarTop from '~/components/BotaoComprarTop';
import { margin } from 'polished';
function Header() {
  return (
    <>
      <Container>
        <div className=" p-2 fundo-topo text-light">
          <div className="container justify-content-center d-flex p-0">
            <div className="text-right m-auto pl-0 topo">
              <small>Desde 1948 | Não testamos em animais</small>
              <img src={Cruelty} className="logo" alt="Logo Crueltyfree" />
              <BotaoComprarTop />
            </div>
            <div className="text-right pr-4 redes-sociais">
              <a href="https://www.amazon.com.br/stores/page/7571F5F3-149A-494E-9052-1469B42EA5B2?ref_=cm_sw_r_ud_sf_stores_E7VMM4Y78YVC5AE5PBCGSC">
                <img src={Amazon} alt="Amazon" style={{ marginRight: '10px', width: "17px" }} />
              </a>
              <a href="https://www.tiktok.com/@anaconda.cosmeticos">
                <img src={Tiktok} alt="tiktok" style={{ marginRight: '10px', width: "17px" }} />
              </a>
              <a href="https://www.facebook.com/Anaconda-Cosm%C3%A9ticos-109160597287290/">
                <FaFacebookF size={17} color="#fff" />
              </a>
              <a href="https://www.instagram.com/anaconda.cosmeticosoficial">
                <FaInstagram className="ml-3" size={17} color="#fff" />
              </a>
            </div>
          </div>
        </div>
        <div className="row m-0  w-100">
          <hr className="col mt-1 m-0" />
        </div>
        <Menu />
      </Container>
    </>
  );
}

export default Header;
