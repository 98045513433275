import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  .fundo-topo {
    background-color: #000;
  }

  hr {
    border: 2px solid #000;
  }

  @media (max-width: 768px) {
    .redes-sociais {
      display: none;
    }
  }

  @media (max-width: 400px) {
    small {
      text-align: left;
      margin: auto;
    }
    .logo {
      margin: auto;
    }
    .topo {
      display: flex;
    }
  }
`;
