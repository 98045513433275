import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from './styles';
import { imgsMobile } from '~/assets/Banner-mobile-topo';
import setaPrev from '~/assets/icons/seta-prev.gif';
import setaNext from '~/assets/icons/seta-next.gif';
import logo from '~/assets/logo_header_neg-2x.png';

function CarouselMobile() {
  let pos = 0;

  window.setInterval(function carrosselAutomatico() {
    // Inicia a contagem de 7 segundos
    let currentPos = pos;
    const nextPos = pos + 1;

    if (
      nextPos < Array.from(document.querySelectorAll('.conhecaMobile')).length
    ) {
      document
        .querySelectorAll('.conhecaMobile')
        [currentPos].classList.toggle('fadein');
      document
        .querySelectorAll('.conhecaMobile')
        [nextPos].classList.toggle('fadein');
      pos += 1;
      currentPos = pos;
      document.querySelector(
        '.banner-topo-mobile'
      ).style.backgroundImage = `url(${imgsMobile[currentPos]})`;
    } else {
      document
        .querySelectorAll('.conhecaMobile')
        [currentPos].classList.toggle('fadein');
      pos = 0;
      currentPos = 0;
      document
        .querySelectorAll('.conhecaMobile')
        [currentPos].classList.toggle('fadein');
      document.querySelector(
        '.banner-topo-mobile'
      ).style.backgroundImage = `url(${imgsMobile[currentPos]})`;
    }
  }, 7000);

  function nextBannerMobileTopo() {
    let currentPos = pos;
    const nextPos = pos + 1;

    if (
      nextPos < Array.from(document.querySelectorAll('.conhecaMobile')).length
    ) {
      document
        .querySelectorAll('.conhecaMobile')
        [currentPos].classList.toggle('fadein');
      document
        .querySelectorAll('.conhecaMobile')
        [nextPos].classList.toggle('fadein');
      pos += 1;
      currentPos = pos;
      document.querySelector(
        '.banner-topo-mobile'
      ).style.backgroundImage = `url(${imgsMobile[currentPos]})`;
    } else {
      document
        .querySelectorAll('.conhecaMobile')
        [currentPos].classList.toggle('fadein');
      pos = 0;
      currentPos = 0;
      document
        .querySelectorAll('.conhecaMobile')
        [currentPos].classList.toggle('fadein');
      document.querySelector(
        '.banner-topo-mobile'
      ).style.backgroundImage = `url(${imgsMobile[currentPos]})`;
    }
  }

  function prevBannerMobileTopo() {
    if (pos - 1 >= 0) {
      document
        .querySelectorAll('.conhecaMobile')
        [pos].classList.toggle('fadein');
      document
        .querySelectorAll('.conhecaMobile')
        [pos - 1].classList.toggle('fadein');
      pos -= 1;
      document.querySelector(
        '.banner-topo-mobile'
      ).style.backgroundImage = `url(${imgsMobile[pos]})`;
    } else {
      pos = Array.from(document.querySelectorAll('.conhecaMobile')).length - 1;
      document.querySelectorAll('.conhecaMobile')[0].classList.toggle('fadein');
      document
        .querySelectorAll('.conhecaMobile')
        [pos].classList.toggle('fadein');
      document.querySelector(
        '.banner-topo-mobile'
      ).style.backgroundImage = `url(${imgsMobile[pos]})`;
    }
  }

  return (
    <>
      <Container>
        <div className="banner-topo-mobile d-flex align-items-center">
          <div className="container d-none conhecaMobile fadein">
            <button
              type="button"
              className="seta-prev my-auto dark"
              onClick={prevBannerMobileTopo}
            >
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>
            <Link
              to={`${process.env.PUBLIC_URL}/maquiagem`}
              className="d-block w-100 h-100"
            />
            <button
              type="button"
              className="seta-next my-auto dark"
              onClick={nextBannerMobileTopo}
            >
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
          <div className="container d-none conhecaMobile">
            <button
              type="button"
              className="seta-prev my-auto dark"
              onClick={prevBannerMobileTopo}
            >
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>
            <Link
              to={`${process.env.PUBLIC_URL}/dermoglam`}
              className="d-block w-100 h-100"
            />
            <button
              type="button"
              className="seta-next my-auto dark"
              onClick={nextBannerMobileTopo}
            >
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
          <div className="container d-none conhecaMobile">
            <button
              type="button"
              className="seta-prev my-auto dark"
              onClick={prevBannerMobileTopo}
            >
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>
            <div className=" ml-4 pl-5 mr-auto" />
            <button
              type="button"
              className="seta-next my-auto dark"
              onClick={nextBannerMobileTopo}
            >
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
          <div className="container d-none conhecaMobile">
            <button
              type="button"
              className="seta-prev my-auto dark"
              onClick={prevBannerMobileTopo}
            >
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>
            <div className=" ml-4 pl-5 mr-auto" id="makeup">
              <h2 className="font-size-4">RETOK</h2>
              <p className="produto-topo-desc mb-5 legenda-banner">
                Retoque naturalmente a cor dos seus cabelos.
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/retok`}
                type="submit"
                className="btn-topo"
                id="btn-makeup"
              >
                EU QUERO!
              </Link>
              <br />
            </div>

            <button
              type="button"
              className="seta-next my-auto dark"
              onClick={nextBannerMobileTopo}
            >
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
          <div className="container d-none conhecaMobile">
            <button
              type="button"
              className="seta-prev my-auto dark"
              onClick={prevBannerMobileTopo}
            >
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>
            <div className=" ml-4 pl-5 mr-auto" id="makeup">
              <h2 className="font-size-4">BIONDINA SUNSHINE</h2>
              <p className="produto-topo-desc mb-5 legenda-banner">
                Clareia e hidrata seus cabelos, sua fórmula enriquecida com aloe
                vera e pantenol fortalece a raiz e os fios dos cabelos, mantendo
                a umidade natural dos fios e proporcionando hidratação intensa,
                brilho e maciez.
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/biondina`}
                type="submit"
                className="btn-topo"
                id="btn-makeup"
              >
                EU QUERO!
              </Link>
              <br />
            </div>

            <button
              type="button"
              className="seta-next my-auto dark"
              onClick={nextBannerMobileTopo}
            >
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
          <div className="container d-none conhecaMobile">
            <button
              type="button"
              className="seta-prev my-auto dark"
              onClick={prevBannerMobileTopo}
            >
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>
            <div className=" ml-4 pl-5 mr-auto" id="makeup">
              <h2 className="font-size-4">CRISTAL GLOSS</h2>
              <p className="produto-topo-desc mb-5 legenda-banner">
                A fórmula com brilho e durabilidade fantástica que atribui e
                mantém a hidratação dos lábios, deixando-os perfeitos.
                Confortável e fácil de aplicar, trazendo luminosidade aos seus
                lábios.
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/maquiagem`}
                type="submit"
                className="btn-topo"
                id="btn-makeup"
              >
                EU QUERO!
              </Link>
              <br />
            </div>

            <button
              type="button"
              className="seta-next my-auto dark"
              onClick={nextBannerMobileTopo}
            >
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
          <div className="container d-none conhecaMobile">
            <button
              type="button"
              className="seta-prev my-auto dark"
              onClick={prevBannerMobileTopo}
            >
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>
            <div className=" ml-4 pl-5 mr-auto" id="makeup">
              <h2 className="font-size-4">CERA MODELADORA</h2>
              <p className="produto-topo-desc mb-5 legenda-banner">
                HAIR WAX STICK
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/#`}
                type="submit"
                className="btn-topo"
                id="btn-makeup"
              >
                EU QUERO!
              </Link>
              <br />
            </div>

            <button
              type="button"
              className="seta-next my-auto dark"
              onClick={nextBannerMobileTopo}
            >
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
          {/*
          <div className="container d-none conhecaMobile">
            <button
              type="button"
              className="seta-prev my-auto dark"
              onClick={prevBannerMobileTopo}
            >
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>
            <div className=" m-3 pl-5 mr-auto" id="makeup">
              <h2 className="font-size-4">BIONDINA</h2>
              <p className="produto-topo-desc mb-5 legenda-banner">
                Clareia os fios e acentua a cor do loiro, deixando as madeixas
                com mais brilho
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/biondina`}
                type="submit"
                className="btn-topo"
                id="btn-makeup"
              >
                EU QUERO!
              </Link>
              <br />
              <img src={logo} alt="Logo Anaconda" className="mt-4" />
            </div>

            <button
              type="button"
              className="seta-next my-auto dark"
              onClick={nextBannerMobileTopo}
            >
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
          <div className="container d-none conhecaMobile">
            <button
              type="button"
              className="seta-prev my-auto "
              onClick={prevBannerMobileTopo}
            >
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>
            <div className=" m-3 pl-5 mr-auto" id="makeup">
              <h2 className="font-size-4 profissional-titulo">PROFISSIONAL</h2>
              <p className="produto-topo-desc mb-5 legenda-banner">
                Conheça nossa linha de produtos profissionais
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/produtos-para-cabelo`}
                type="submit"
                className="btn-topo"
                id="btn-makeup"
              >
                EU QUERO!
              </Link>
              <br />
              <img src={logo} alt="Logo Anaconda" className="mt-4" />
            </div>

            <button
              type="button"
              className="seta-next my-auto"
              onClick={nextBannerMobileTopo}
            >
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
          <div className="container d-none conhecaMobile">
            <button
              type="button"
              className="seta-prev my-auto "
              onClick={prevBannerMobileTopo}
            >
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>
            <div className=" m-3 pl-5 mr-auto" id="makeup">
              <h2 className="font-size-4">RETOK</h2>
              <p className="produto-topo-desc mb-5 legenda-banner">
                Conheça nossa linha de produtos para cabelos
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/retok`}
                type="submit"
                className="btn-topo"
                id="btn-makeup"
              >
                EU QUERO!
              </Link>
              <br />
              <img src={logo} alt="Logo Anaconda" className="mt-4" />
            </div>

            <button
              type="button"
              className="seta-next my-auto dark"
              onClick={nextBannerMobileTopo}
            >
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
          <div className="container d-none conhecaMobile">
            <button
              type="button"
              className="seta-prev my-auto "
              onClick={prevBannerMobileTopo}
            >
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>
            <div className=" ml-3 pl-5 mr-auto" id="makeup">
              <h2 className="font-size-4 dermoglam-titulo">DERMOGLAM</h2>
              <p className="produto-topo-desc mb-5 legenda-banner">
                Conheça nossa linha anti-age
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/dermoglam`}
                type="submit"
                className="btn-topo"
                id="btn-makeup"
              >
                EU QUERO!
              </Link>
              <br />
              <img src={logo} alt="Logo Anaconda" className="mt-4" />
            </div>

            <button
              type="button"
              className="seta-next my-auto dark"
              onClick={nextBannerMobileTopo}
            >
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
          <div className="container d-none conhecaMobile">
            <button
              type="button"
              className="seta-prev my-auto dark"
              onClick={prevBannerMobileTopo}
            >
              <img src={setaPrev} alt="Ícone seta anterior " />
            </button>
            <div className=" ml-5 pl-5 mr-auto" id="makeup">
              <h2 className="font-size-4">MAKEUP</h2>
              <p className="produto-topo-desc mb-5 legenda-banner">
                Conheça nossa linha de maquiagem
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/maquiagem`}
                type="submit"
                className="btn-topo"
                id="btn-makeup"
              >
                EU QUERO!
              </Link>
              <br />
              <img src={logo} alt="Logo Anaconda" className="mt-4" />
            </div>

            <button
              type="button"
              className="seta-next my-auto"
              onClick={nextBannerMobileTopo}
            >
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
          <div className="container d-none conhecaMobile ">
            <button
              type="button"
              className="seta-prev my-auto "
              onClick={prevBannerMobileTopo}
            >
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>
            <div className=" ml-5 pl-5 mr-auto" id="skinCare">
              <h2 className="font-size-4">SKIN CARE</h2>
              <p className="produto-topo-desc mb-5 legenda-banner">
                Conheça nossa linha de <br />
                produtos para o rosto
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/clareador-facial`}
                type="submit"
                className="btn-topo"
                id="btn-skinCare"
              >
                EU QUERO!
              </Link>
              <br />
              <img src={logo} alt="Logo Anaconda" className="mt-4" />
            </div>

            <button
              type="button"
              className="seta-next my-auto dark"
              onClick={nextBannerMobileTopo}
            >
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div> */}
        </div>
      </Container>
    </>
  );
}

export default CarouselMobile;
