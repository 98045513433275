import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from './styles';
import Footer from '~/components/Footer';
import LocalCatalogo from '~/components/LocalCatalogo';
import BannerCatalogo from '~/components/BannerCatalogo';
import { imgs } from '~/assets/biondina';
import setaPrev from '~/assets/icons/seta-prev.gif';
import setaNext from '~/assets/icons/seta-next.gif';
import BotaoComprar from '~/components/BotaoComprar';

function Biondina() {
  let pos = 0;

  function next() {
    if (pos + 1 < Array.from(document.querySelectorAll('.info-prod')).length) {
      const width = document.querySelector('.infos').offsetWidth;
      document.querySelector('.infos').scrollLeft += width;
      pos += 1;
      document.querySelector('.seta-prev').style.visibility = 'visible';
      if (
        pos ===
        Array.from(document.querySelectorAll('.info-prod')).length - 1
      ) {
        document.querySelector('.seta-next').style.visibility = 'hidden';
      } else {
        document.querySelector('.seta-prev').style.visibility = 'visible';
      }
    }
  }

  function prev() {
    if (pos - 1 >= 0) {
      const width = document.querySelector('.infos').offsetWidth;
      document.querySelector('.infos').scrollLeft -= width;
      pos -= 1;
      document.querySelector('.seta-next').style.visibility = 'visible';
      if (pos === 0) {
        document.querySelector('.seta-prev').style.visibility = 'hidden';
      } else {
        document.querySelector('.seta-prev').style.visibility = 'visible';
      }
    }
  }
  return (
    <>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Anaconda Cosméticos - Biondina Clareador</title>
          <meta
            name="description"
            content="O Biondina Clareador da Anaconda Cosméticos foi desenvolvido para clarear os cabelos. Com Biondina os cabelos ficam loiros, bonitos e saudáveis sem química."
          />
        </Helmet>
        <div className="row-100 fundo-destaque">
          <div className="container h-100 d-flex">
            <div className="ml-5 mobile-ml-1 my-auto">
              <h2 className="text-uppercase ">Biondina</h2>
              <p className="desc-produto ">
                O Biondina Clareador da Anaconda Cosméticos foi desenvolvido
                para clarear os cabelos. Com Biondina os cabelos ficam loiros,
                bonitos e saudáveis sem química
              </p>
            </div>
          </div>
        </div>
        <div className="row-100 ">
          <div className="container border bg-white mt-n5 px-4 py-4">
            <h1 className="h2 font-weight-bold text-center py-5 m-auto frase-principal">
              Pensando em ter cabelos loiros e sem química? Biondina!
            </h1>
            <BotaoComprar/>
          </div>
        </div>
        <div className="row-100 fundo-tarja">
          <div className="container d-flex h-100">
            <h2 className="h3 text-uppercase text-center m-auto frase-tarja">
              Tenha cabelos iluminados e mais saudáveis com aspecto de loiro
              natural
            </h2>
          </div>
        </div>

        <div className="row-100 bg-white border-left border-right border-bottom py-5 mobile-row">
          <div className="container d-flex">
            <button type="button" className="seta-prev my-auto " onClick={prev}>
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>

            <div className="row align-items-center infos">
              <div className="info-prod row px-5">
                <div className="col">
                  <h3 className="h4 font-weight-bold pt-5 pb-4">
                    Máscara hidratação profunda:
                  </h3>
                  <p>
                    Restauração dos fios descoloridos, fortalecendo a fibra
                    capilar e devolvendo brilho aos cabelos loiros e com mechas.
                  </p>
                  <h3 className="h4 font-weight-bold">
                    Sua fórmula é enriquecida com ativos orgânicos como:
                  </h3>
                  <ul>
                    <li>• Extrato de camomila</li>
                    <li>• Óleo de abacate</li>
                    <li>• Óleo de argan</li>
                    <li>• Manteiga de karité</li>
                    <li>• Vitamina E</li>
                  </ul>
                  <p className="font-weight-bold">Produto sem parabenos.</p>
                </div>
                <div className="col d-flex ">
                  <img
                    className="border border-secondary my-auto"
                    src={imgs.mascaraHidratacao}
                    alt="Máscara hidratação profunda"
                  />
                </div>
              </div>
              <div className="info-prod row px-5">
                <div className="col">
                  <h3 className="font-weight-bold pt-5 pb-4 formula-perfeita">
                    A fórmula perfeita para clarear os seus cabelos!
                  </h3>
                  <p>
                    A Anaconda Cosméticos desenvolveu uma fórmula de sucesso no
                    mercado para cabelos loiros. A linha Biondina clareia os
                    fios, realça a cor do loiro natural e oferece mais brilho
                    para os seus cabelos, deixando um aspecto natural e
                    saudável.
                  </p>
                </div>
                <div className="col d-flex ">
                  <img
                    className="border border-secondary my-auto"
                    src={imgs.refil}
                    alt="Biondina Refil"
                  />
                </div>
              </div>
              <div className="info-prod row px-5 ">
                <div className="col">
                  <h3 className="h4 font-weight-bold pt-5 pb-4">
                    Um produto natural que não agride os fios
                  </h3>
                  <p>
                    Toda a linha Biondina possui em sua composição o extrato de
                    camomila, um ingrediente natural que contribui para o
                    clareamento dos cabelos sem danificar os fios.
                  </p>
                </div>
                <div className="col d-flex ">
                  <img
                    className="border border-secondary my-auto"
                    src={imgs.biondinaRefil}
                    alt="Biondina Refil"
                  />
                </div>
              </div>
              <div className="info-prod row px-5">
                <div className="col">
                  <h3 className="h4 font-weight-bold pt-5 pb-4">
                    Quanto mais você passar, mais claro vai ficar!
                  </h3>
                  <p>
                    A Biondina não é uma tinta para cabelos, por isso, o
                    processo de clareamento é gradual, ou seja, o seu cabelo vai
                    ficando mais loiro de acordo com a frequência do uso. Para
                    ter um efeito mais bonito e duradouro, é necessário usar
                    toda a linha completa de produtos Biondina: Spray, Shampoo,
                    Condicionador e Creme.
                  </p>
                </div>
                <div className="col d-flex ">
                  <img
                    className="border border-secondary my-auto"
                    src={imgs.biondina}
                    alt="Biondia Shampoo e Condicionador"
                  />
                </div>
              </div>
              <div className="info-prod row px-5">
                <div className="col">
                  <h3 className="h4 font-weight-bold pt-5 pb-4">
                    Veja como usar
                  </h3>
                  <p>
                    Lave os cabelos normalmente com shampoo e condicionador
                    Biondina e depois aplique o creme Biondina. Após isso,
                    penteie os seus cabelos e borrife o Spray Biondina nos fios.
                    Para finalizar e conseguir o efeito de clareamento, é
                    necessário ativar o produto secando no calor do sol ou com o
                    secador de cabelos. Se quiser cabelos mais claros é preciso
                    repetir esse processo várias vezes.
                  </p>
                </div>
                <div className="col d-flex ">
                  <img
                    className="border border-secondary my-auto"
                    src={imgs.familia}
                    alt="Familia Biondina"
                  />
                </div>
              </div>
              <div className="info-prod row px-5">
                <div className="col">
                  <h3 className="h4 font-weight-bold pt-5 pb-4">
                    Informações importantes
                  </h3>
                  <ul>
                    <li>• A linha Biondina não faz teste em animais.</li>
                    <li>• Produtos não indicados para gestantes.</li>
                    <li>• Produtos não indicados para cabelos com química.</li>
                    <li>• Não possui cobertura para fios brancos.</li>
                  </ul>
                </div>
                <div className="col d-flex ">
                  <img
                    className="border border-secondary my-auto"
                    src={imgs.beach}
                    alt="Biondina Beach"
                  />
                </div>
              </div>
            </div>

            <button type="button" className="seta-next my-auto" onClick={next}>
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
        </div>
      </Container>
      <BannerCatalogo />
      <LocalCatalogo />
      <Footer />
    </>
  );
}

export default Biondina;
