import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from './styles';
import Footer from '~/components/Footer';
import LocalCatalogo from '~/components/LocalCatalogo';
import BannerCatalogo from '~/components/BannerCatalogo';
import { imgs } from '~/assets/skin';
import setaPrev from '~/assets/icons/seta-prev.gif';
import setaNext from '~/assets/icons/seta-next.gif';

function Biondina() {
  let pos = 0;

  function next() {
    if (pos + 1 < Array.from(document.querySelectorAll('.info-prod')).length) {
      const width = document.querySelector('.infos').offsetWidth;
      document.querySelector('.infos').scrollLeft += width;
      pos += 1;
      document.querySelector('.seta-prev').style.visibility = 'visible';
      if (
        pos ===
        Array.from(document.querySelectorAll('.info-prod')).length - 1
      ) {
        document.querySelector('.seta-next').style.visibility = 'hidden';
      } else {
        document.querySelector('.seta-prev').style.visibility = 'visible';
      }
    }
  }

  function prev() {
    if (pos - 1 >= 0) {
      const width = document.querySelector('.infos').offsetWidth;
      document.querySelector('.infos').scrollLeft -= width;
      pos -= 1;
      document.querySelector('.seta-next').style.visibility = 'visible';
      if (pos === 0) {
        document.querySelector('.seta-prev').style.visibility = 'hidden';
      } else {
        document.querySelector('.seta-prev').style.visibility = 'visible';
      }
    }
  }
  return (
    <>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Anaconda Cosméticos – Clareador Facial</title>
          <meta
            name="description"
            content="Conheça a linha de produtos para tratamento da face, clareamento e limpeza profunda da Anaconda Cosméticos."
          />
        </Helmet>
        <div className="row-100 fundo-destaque">
          <div className="container h-100 d-flex">
            <div className="ml-5 mobile-ml-1 my-auto">
              <h2 className="h1 text-uppercase limpeza">
                Limpeza e clareador para sua pele
              </h2>
              <p className="desc-produto ">
                Conheça a linha de produtos para tratamento da face, clareamento
                e limpeza profunda da Anaconda Cosméticos.
              </p>
            </div>
          </div>
        </div>
        <div className="row-100 ">
          <div className="container border bg-white mt-n5 px-4 py-4">
            <h1 className="font-weight-bold text-center py-5 m-auto frase-principal">
              Tudo que você precisa para a sua rotina de skin care
            </h1>
          </div>
        </div>
        <div className="row-100 fundo-tarja">
          <div className="container d-flex h-100">
            <h2 className="h6 w-50 text-uppercase text-center m-auto">
              Produtos para uma pele limpa, bem cuidada e renovada
            </h2>
          </div>
        </div>

        <div className="row-100 bg-white border-left border-right border-bottom py-5 mobile-row">
          <div className="container d-flex">
            <button type="button" className="seta-prev my-auto " onClick={prev}>
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>

            <div className="row align-items-center infos">
              <div className="info-prod row px-5">
                <div className="col">
                  <h2 className="h4 font-weight-bold pt-5 pb-4">
                    Creme Clareador:
                  </h2>
                  <h3 className="h5 font-weight-bold">
                    Dê adeus às manchas causadas pelo sol, gravidez e idade:
                  </h3>
                  <p>
                    Desenvolvido pela Anaconda Cosméticos, o creme clareador é
                    ideal para tratar pequenas manchas na pele. O produto é
                    criado especialmente para quem deseja eliminar peles
                    escurecidas, como marcas por exposição ao sol, gravidez e
                    idade.
                  </p>
                  <h3 className="h4 font-weight-bold pt-5 pb-4">
                    Veja como usar:
                  </h3>
                  <p>
                    O creme clareador deve ser usado preferencialmente à noite
                    para um efeito melhor. Durante o dia, o ideal é retirar o
                    produto e abusar do protetor solar com FPS 30, no mínimo.
                  </p>
                </div>
                <div className="col d-flex ">
                  <img
                    className="border border-secondary my-auto"
                    src={imgs.clareador}
                    alt="Creme Clareador"
                  />
                </div>
              </div>
              <div className="info-prod row px-5">
                <div className="col">
                  <h2 className="h4 font-weight-bold pt-5 pb-4">
                    Adstringentes:
                  </h2>
                  <h3 className="h5 font-weight-bold">
                    Elimine as impurezas do dia a dia
                  </h3>
                  <p>
                    A linha de adstringente foi desenvolvida para um cuidado
                    completo com a pele. Com uma limpeza profunda da pele e
                    higienização dos poros, o adstringente remove até maquiagem
                    e está disponível em três versões: adstringente Cânfora,
                    indicado para peles mistas e oleosas, adstringente Aloe
                    Vera, indicado para peles normais e secas e adstringente
                    Suave, indicado para peles sensíveis.
                  </p>
                  <h3 className="h4 font-weight-bold pt-5 pb-4">
                    Veja como usar:
                  </h3>
                  <p>
                    Lave o rosto com um sabonete adequado para o seu tipo de
                    pele e aplique o adstringente com algodão. O ideal é aplicar
                    duas vezes ao dia, uma pela manhã e outra à noite.
                  </p>
                </div>
                <div className="col d-flex ">
                  <img
                    className="border border-secondary my-auto"
                    src={imgs.adstringentes}
                    alt="Adstringentes"
                  />
                </div>
              </div>
              <div className="info-prod row px-5">
                <div className="col">
                  <h2 className="h4 font-weight-bold pt-5 pb-4">
                    Água Micelar:
                  </h2>
                  <h3 className="h5 font-weight-bold">
                    Limpe os poros, remova a maquiagem, purifique a pele:
                  </h3>
                  <p>
                    A Anaconda Cosméticos desenvolveu águas micelares para
                    remoção de impurezas, hidratação e tonificação. Os produtos
                    são 5 em 1, com uma fórmula não oleosa, sem álcool e ideal
                    para todo tipo de pele.
                  </p>
                  <h3 className="h4 font-weight-bold pt-5 pb-4">
                    Veja como usar:
                  </h3>
                  <p>
                    Utilize o produto duas vezes ao dia, no período da manhã e à
                    noite. Aplique com um algodão limpo e faça movimentos
                    circulares para remover todas as impurezas dos poros.
                  </p>
                </div>
                <div className="col d-flex ">
                  <img
                    className="border border-secondary my-auto"
                    src={imgs.aguaMicelar}
                    alt="Água Micelar"
                  />
                </div>
              </div>
            </div>
            <button type="button" className="seta-next my-auto" onClick={next}>
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
        </div>
      </Container>
      <BannerCatalogo />
      <LocalCatalogo />
      <Footer />
    </>
  );
}

export default Biondina;
