import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container } from './styles';
import LocalCatalogo from '~/components/LocalCatalogo';
import imgContato from '~/assets/foto-contato.jpg';
import FooterContato from '~/components/FooterContato';
import api from '../../services/api';

function Contato() {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  let button;
  if (loading) {
    button = (
      <div className="btn mb-2">
        <div className="loader" />
      </div>
    );
  } else {
    button = (
      <button type="submit" className="btn mb-2">
        Enviar
      </button>
    );
  }

  const handleSubmit = async event => {
    setLoading(true);
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const response = await api.post('/contato', formData);
    setLoading(false);
    form.reset();
    if (response.status === 200) {
      const messageDiv = (
        <div className="alert alert-success">Mensagem enviada com sucesso!</div>
      );
      setMessage(messageDiv);
    } else {
      const messageDiv = (
        <div className="alert alert-danger">Erro ao enviar a mensagem!</div>
      );
      setMessage(messageDiv);
    }
  };
  return (
    <>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Anaconda Cosméticos – Contato</title>
          <meta
            name="description"
            content="Ficou com alguma dúvida, precisa de atendimento? Mande mensagem e fale conosco!"
          />
        </Helmet>
        <div className="row-100 banner-contatos">
          <div className="container text-right">
            <h1 className="mr-5">FALE CONOSCO!</h1>
          </div>
        </div>
        <div className="row-100 my-4">
          <div className="container d-flex">
            <img
              src={imgContato}
              alt="Imagem contato"
              className="col-5 border border-secondary p-0 desk-only"
            />

            <div className="col-7 p-4 mobile-form">
              <h2 className="h3 font-weight-bold">Mande uma mensagem!</h2>
              <form
                onSubmit={handleSubmit}
                id="form-contato"
                encType="multipart/form-data"
              >
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control  my-4"
                    id="nome"
                    placeholder="Digite seu nome"
                    name="nome"
                    required
                  />
                </div>
                <div className="form-group ">
                  <input
                    type="email"
                    className="form-control  my-4"
                    id="email"
                    placeholder="Seu email"
                    name="email"
                    required
                  />
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    className="form-control  my-4"
                    id="assunto"
                    placeholder="Qual o assunto?"
                    name="assunto"
                    required
                  />
                </div>

                <div className="form-group">
                  <textarea
                    className="form-control  my-4"
                    id="duvida"
                    rows="3"
                    placeholder="Qual é a sua dúvida?"
                    name="mensagem"
                    required
                  />
                </div>

                {button}
              </form>
              {message}
            </div>
          </div>
        </div>
        <LocalCatalogo />
        <FooterContato />
      </Container>
    </>
  );
}

export default Contato;
