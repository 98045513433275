const img = require('./adstringentes.png');
const img2 = require('./esfoliante.png');
const img3 = require('./clareador.png');
const img4 = require('./aguaMicelar.png');

export const imgs = {
  adstringentes: img,
  esfoliante: img2,
  clareador: img3,
  aguaMicelar: img4,
};
