/* eslint-disable func-names */
import React from 'react';

import { FaFacebookF, FaInstagram } from 'react-icons/fa';

import { Link } from 'react-router-dom';

import { Container } from './styles';
import logo from '~/assets/logo_header_neg-2x.png';
import PDF from '~/pages/Catalogo/catalogo.pdf';

function Menu() {
  function toggleMenu() {
    const menu = document.querySelector('.navbar');
    const isMenuClosed = document.getElementsByClassName('slide-in-left');
    const isMenuOpened = document.getElementsByClassName('slide-out-left');
    if (isMenuOpened.length !== 0) {
      menu.classList.remove('slide-out-left');
      menu.classList.add('slide-in-left');
      document.querySelector('.icon--menu').classList.add('icon--close');
      document.querySelector('.icon--menu').classList.remove('icon--menu');
      document.querySelector('.span-3').classList.add('show');
    } else if (isMenuClosed.length !== 0) {
      menu.classList.add('slide-out-left');
      menu.classList.remove('slide-in-left');
      document.querySelector('.icon--close').classList.add('icon--menu');
      document.querySelector('.icon--close').classList.remove('icon--close');
      document.querySelector('.span-3').classList.remove('show');
    }
  }
  function closeMenu() {
    const menu = document.querySelector('.navbar');
    menu.classList.add('slide-out-left');
    menu.classList.remove('slide-in-left');
    document.querySelector('.span-3').classList.remove('show');
  }

  return (
    <Container className="container p-0">
      <div className="row m-0 w-100">
        <button
          className="mobile-only menu-btn mt-4 ml-5"
          type="button"
          onClick={toggleMenu}
        >
          <span className="icon icon--menu">
            <span />
            <span />
            <span className="span-3" />
          </span>
        </button>
        <img className=" mobile-only logo" src={logo} alt="Logo Anaconda" />
      </div>
      <nav className="navbar navbar-expand-md py-2 slide-out-left">
        <ul className="navbar-nav justify-content-between p-0 w-100">
          <li className="nav-item mr-auto d-flex">
            <Link
              to={`${process.env.PUBLIC_URL}/`}
              onClick={closeMenu}
              className="nav-link m-auto"
            >
              HOME
            </Link>
          </li>
          <li className="text-center nav-item mr-auto  d-flex align-items-center">
            <Link
              to={`${process.env.PUBLIC_URL}/empresa-de-cosmeticos`}
              onClick={closeMenu}
              className="nav-link"
            >
              QUEM SOMOS
            </Link>
          </li>
          <li className="nav-item mr-auto d-flex produtos">
            <div
              // to={`${process.env.PUBLIC_URL}/produtos`}
              // onClick={closeMenu}
              className="nav-link m-auto"
            >
              <Link
                to={`${process.env.PUBLIC_URL}/produtos`}
                onClick={closeMenu}
                className="nav-link desk-only"
              >
                PRODUTOS
              </Link>
              <span className="mobile-only">PRODUTOS</span>
              <ul className="submenu">
                <li>
                  <Link
                    to={`${process.env.PUBLIC_URL}/biondina`}
                    onClick={closeMenu}
                  >
                    Biondina
                  </Link>
                </li>
                <li>
                  <Link
                    to={`${process.env.PUBLIC_URL}/retok`}
                    onClick={closeMenu}
                  >
                    Retok
                  </Link>
                </li>
                <li>
                  <Link
                    to={`${process.env.PUBLIC_URL}/dermoglam`}
                    onClick={closeMenu}
                  >
                    Dermoglam
                  </Link>
                </li>
                <li>
                  <Link
                    to={`${process.env.PUBLIC_URL}/maquiagem`}
                    onClick={closeMenu}
                  >
                    Maquiagem
                  </Link>
                </li>
                <li>
                  <Link
                    to={`${process.env.PUBLIC_URL}/retok-legs-e-corporal`}
                    onClick={closeMenu}
                  >
                    RetokLegs & Corporal
                  </Link>
                </li>
                <li>
                  <Link
                    to={`${process.env.PUBLIC_URL}/clareador-facial`}
                    onClick={closeMenu}
                  >
                    Limpeza e Clareador
                  </Link>
                </li>
              </ul>
            </div>
          </li>
          <img
            className="desk-only m-auto logo"
            src={logo}
            alt="Logo Anaconda"
          />
          <li className="nav-item d-flex">
            <a
              href={PDF}
              target="_blank"
              rel="noreferrer"
              onClick={closeMenu}
              className="nav-link m-auto"
            >
              CATÁLOGO
            </a>
          </li>
          <li className="nav-item ml-auto  d-flex align-items-center">
            <Link
              to={`${process.env.PUBLIC_URL}/onde-encontrar`}
              onClick={closeMenu}
              className="nav-link text-center "
            >
              ONDE ENCONTRAR
            </Link>
          </li>
          <li className="nav-item ml-auto d-flex">
            <Link
              to={`${process.env.PUBLIC_URL}/contato`}
              onClick={closeMenu}
              className="nav-link m-auto"
            >
              CONTATO
            </Link>
          </li>
          <li className="d-flex mobile-only-social">
            <a
              href="https://www.facebook.com/Anaconda-Cosm%C3%A9ticos-109160597287290/"
              className="nav-link"
            >
              <FaFacebookF size={38} color="#000" />
            </a>
            <a
              href="https://www.instagram.com/anaconda.cosmeticosoficial/?hl=pt-br"
              className="nav-link ml-4"
            >
              <FaInstagram size={38} color="#000" />
            </a>
          </li>
        </ul>
      </nav>
    </Container>
  );
}

export default Menu;
