import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from './styles';
import LocalCatalogo from '~/components/LocalCatalogo';
import BannerCatalogo from '~/components/BannerCatalogo';

import Footer from '~/components/Footer';

function Sobre() {
  return (
    <>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Anaconda - Empresa de Cosméticos</title>
          <meta
            name="description"
            content="Conheça a Anaconda Cosméticos, uma empresa fabricante e distribuidora de dermocosméticos, maquiagem e produtos para o corpo."
          />
        </Helmet>
        <div className="row-100">
          <div className="col mx-auto my-3 text-center">
            <h1 className="font-weight-bold">
              Uma empresa de cosméticos
              <br /> cruelty-free
            </h1>
            <p className="subtitulo">
              Conheça a Anaconda Cosméticos, uma empresa fabricante e
              distribuidora de dermocosméticos, maquiagem e produtos para o
              corpo e cabelo.
            </p>
          </div>
        </div>
        <div className="row-100 d-flex img-texto">
          <div className="w-50 mobile  border-secondary border img-quem-somos" />

          <div className="w-50 border  border-secondary fundo-texto pt-4 pl-4">
            <div className="">
              <h2 className="font-weight-bold eficacia-comprovada">
                Anaconda Cosméticos possui produtos dermatologicamente testados,
                com eficácia comprovada
              </h2>
              <p className="texto-quem-somos">
                A história da <b>Anaconda Cosméticos</b> teve início em 1948
                como uma escola de cabeleireiros. Foi quando os fundadores, que
                eram cabeleireiros e farmacêuticos, começaram no{' '}
                <b>mercado da beleza</b>, com a fabricação de{' '}
                <b>produtos para cabelo</b>, corpo, maquiagens e{' '}
                <b>dermocosméticos</b>.
              </p>
              <p className="texto-quem-somos">
                A Anaconda é uma <b>empresa 100% nacional</b> que utiliza
                tecnologia americana, francesa e alemã. Os{' '}
                <b>produtos de beleza</b> e tratamento são <b>cruelty-free</b>,
                não testados em animais, atendem todos os padrões estabelecidos
                pela Anvisa e são microbiologicamente testados.
              </p>
              <p className="texto-quem-somos">
                Além disso, a Anaconda também possui uma linha de{' '}
                <b>dermocosméticos</b>, a DermoGlam, dermatologicamente testada
                e com eficácia comprovada para o tratamento da saúde da pele.
                Todos os produtos da empresa são fabricados em local próprio, na
                cidade de São Paulo.
              </p>
              <p className="texto-quem-somos">
                Em sete décadas, a <b>Anaconda Cosméticos</b> oferece qualidade
                e tradição em produtos como: Biondina Loção Clareadora, Retok
                Stick para cabelos e Linha Espuma Nylon. Com 72 anos de mercado,
                a <b>empresa de cosméticos</b> utiliza pesquisas científicas e
                tecnológicas de última geração para desenvolver os melhores
                produtos com a finalidade de satisfazer ainda mais os
                consumidores.
              </p>
            </div>
          </div>
        </div>
      </Container>
      <LocalCatalogo />
      <BannerCatalogo />
      <Footer />
    </>
  );
}

export default Sobre;
