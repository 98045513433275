const img = require('./castanho.png');
const img2 = require('./hairSprayPreto.png');
const img3 = require('./liquido.png');
const img4 = require('./loiro.png');
const img5 = require('./preto.png');

export const imgs = {
  castanho: img,
  spray: img2,
  liquido: img3,
  loiro: img4,
  preto: img5,
};
