import React from 'react';

function BotaoComprar() {
return(
    <div className="centralizar">
    <a href="https://www.amazon.com.br/stores/page/7571F5F3-149A-494E-9052-1469B42EA5B2?ref_=cm_sw_r_ud_sf_stores_E7VMM4Y78YVC5AE5PBCG"
    className="btn2 position-relative" target='_blank'>
    COMPRAR ONLINE
  </a>
  </div>
);
}

export default BotaoComprar;