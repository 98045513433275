import React from 'react';
import { Helmet } from 'react-helmet';
import { Container } from './styles';
import Footer from '~/components/Footer';
import LocalCatalogo from '~/components/LocalCatalogo';
import BannerCatalogo from '~/components/BannerCatalogo';
import { imgs } from '~/assets/retok';
import setaPrev from '~/assets/icons/seta-prev.gif';
import setaNext from '~/assets/icons/seta-next.gif';
import BotaoComprar from '~/components/BotaoComprar';
function Retok() {
  let pos = 0;

  function next() {
    if (pos + 1 < Array.from(document.querySelectorAll('.info-prod')).length) {
      const width = document.querySelector('.infos').offsetWidth;
      document.querySelector('.infos').scrollLeft += width;
      pos += 1;
      document.querySelector('.seta-prev').style.visibility = 'visible';
      if (
        pos ===
        Array.from(document.querySelectorAll('.info-prod')).length - 1
      ) {
        document.querySelector('.seta-next').style.visibility = 'hidden';
      } else {
        document.querySelector('.seta-prev').style.visibility = 'visible';
      }
    }
  }

  function prev() {
    if (pos - 1 >= 0) {
      const width = document.querySelector('.infos').offsetWidth;
      document.querySelector('.infos').scrollLeft -= width;
      pos -= 1;
      document.querySelector('.seta-next').style.visibility = 'visible';
      if (pos === 0) {
        document.querySelector('.seta-prev').style.visibility = 'hidden';
      } else {
        document.querySelector('.seta-prev').style.visibility = 'visible';
      }
    }
  }
  return (
    <>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Anaconda Cosméticos – Retok</title>
          <meta
            name="description"
            content="Retok é uma linha completa de produtos Anaconda Cosméticos para o retoque da raiz dos cabelos, barba e bigode com cobertura instantânea dos fios"
          />
        </Helmet>
        <div className="row-100 fundo-destaque">
          <div className="container h-100 d-flex">
            <div className="ml-5 mobile-ml-1 my-auto">
              <h2 className="retok">RETOK</h2>
              <p className="desc-produto ">
                Retok é uma linha completa de produtos Anaconda Cosméticos para
                o retoque da raiz dos cabelos, barba e bigode com cobertura
                instantânea dos fios
              </p>
            </div>
          </div>
        </div>
        <div className="row-100 ">
          <div className="container border bg-white mt-n5 px-4 py-4">
            <h1 className="h2 font-weight-bold text-center py-5 m-auto frase-principal">
              Retok: cuidados instantâneos para melhorar a sua aparência e
              autoestima
            </h1>
            <BotaoComprar/>
          </div>
        </div>
        <div className="row-100 fundo-tarja">
          <div className="container d-flex h-100">
            <h2 className="h6 w-50 text-uppercase text-center m-auto">
              Produtos para maquiagem capilar, retoque de raiz e cobertura dos
              cabelos&nbsp;brancos
            </h2>
          </div>
        </div>
        <div className="row-100 my-5">
          <div className="container d-flex h-100">
            <h2 className="h5 text-uppercase text-center m-auto font-weight-bold">
              Conheça a linha Retok da Anaconda Cosméticos
            </h2>
          </div>
        </div>
        <div className="row-100 bg-white border-left border-right border-bottom py-5 mobile-row">
          <div className="container d-flex">
            <button type="button" className="seta-prev my-auto " onClick={prev}>
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>

            <div className="row align-items-center infos">
              <div className="info-prod row px-5">
                <div className="col">
                  <h2 className="h4 font-weight-bold pt-5 pb-4">
                    Retok Hair Spray:
                  </h2>
                  <h3 className="h5 font-weight-bold">
                    Ideal para cobrir cabelos brancos
                  </h3>
                  <p>
                    Desenvolvido para a cobertura dos grisalhos. O produto é uma
                    maquiagem capilar temporária que possui fácil aplicação e
                    funciona de forma instantânea, oferecendo cobertura para:
                    raiz dos cabelos, barba e bigode. O Retok Hair está
                    disponível nas cores preto, castanho claro, castanho escuro
                    e loiro.
                  </p>
                  <h3 className="h5 font-weight-bold">Veja como usar:</h3>
                  <p>
                    O produto vem em um tubo de spray e deve ser agitado antes
                    de usar. Depois, separe o local em que deseja aplicar o
                    produto e aplique de forma contínua, respeitando a distância
                    de 2 a 3 cm. Passe o Retok Hair por todo o local e evite
                    concentrar o jato do spray em apenas um único ponto, assim o
                    produto fica mais natural e uniforme. Para remover, basta
                    lavar com shampoo.
                  </p>
                </div>
                <div className="col d-flex col-img-produto">
                  <img
                    className="border border-secondary my-auto"
                    src={imgs.spray}
                    alt="Retok Hair Spray"
                  />
                </div>
              </div>

              <div className="info-prod row px-5 ">
                <div className="col">
                  <h2 className="h4 font-weight-bold pt-5 pb-4">New Retok:</h2>
                  <h3 className="h5 font-weight-bold">
                    Desenvolvido para homens modernos e estilosos
                  </h3>
                  <p>
                    Perfeito para cobrir as imperfeições e os grisalhos da barba
                    e bigode, mas também pode ser usado nos cabelos. Para
                    facilitar a aplicação, possui formato de bastão para tingir
                    todos os espaços desejados, proporcionando um resultado
                    natural e uniforme. O produto está disponível nas cores
                    castanho e preto e contém Cera de Carnaúba para evitar
                    ressecamento, trazendo mais brilho e maciez.
                  </p>
                  <p>
                    Outro produto da linha é o Óleo de Barba composto por óleo
                    de coco, óleo de abacate e óleo de semente de uva para
                    hidratar a barba com uma fragrância suave e agradável.
                  </p>
                  <h3 className="h5 font-weight-bold">Veja como usar:</h3>
                  <p>
                    Aplique o produto diretamente sobre a área desejada e deixe
                    secar por alguns instantes. O New Retok serve para todos os
                    tipos de barbas e bigodes e pode ser usado diariamente. O
                    produto não possui nenhuma restrição de uso, não mancha
                    roupas e só sai quando lavado com shampoo.
                  </p>
                </div>
                <div className="col d-flex  col-img-produto">
                  <img
                    className="border border-secondary my-auto"
                    src={imgs.castanho}
                    alt="New Retok Barba e Bigode"
                  />
                </div>
              </div>
              <div className="info-prod row px-5">
                <div className="col">
                  <h2 className="h4 font-weight-bold pt-5 pb-4">
                    Retok Coloração:
                  </h2>
                  <h3 className="h5 font-weight-bold">
                    Cor renovada em instantes
                  </h3>
                  <p>
                    A solução para quem precisa retocar a cor instantaneamente.
                    O Retok Coloração serve para cobrir o grisalho, retocar a
                    raiz do cabelo e a tintura, proporcionando uma cobertura
                    natural e uniforme. O produto é ideal para todos os tipos de
                    cabelo, é compatível com qualquer coloração e não resseca os
                    fios, pois contém óleos e ceras naturais em sua composição.
                  </p>
                  <p>
                    Cores disponíveis: 1.0 preto (Natural e Azulado) - 2.0 Preto
                    - 3.0 Castanho Escuro - 4.0 Castanho Médio - 5.0 Castanho
                    Claro - e 5.5 Acaju e vermelhos acobreados - 6.0 Loiro
                    Escuro - 7.0 Loiro Médio - 8.0 Loiro Claro.
                  </p>
                  <h3 className="h5 font-weight-bold">Veja como usar:</h3>
                  <p>
                    Para aplicar, puxe bem os fios para baixo até deixar a raiz
                    rente à cabeça, depois passe o bastão para retocar toda a
                    área desejada. Para remover, basta lavar com shampoo.
                  </p>
                </div>
                <div className="col d-flex  col-img-produto">
                  <img
                    className="border border-secondary my-auto"
                    src={imgs.preto}
                    alt="Retok Coloração"
                  />
                </div>
              </div>
              <div className="info-prod row px-5">
                <div className="col">
                  <h2 className="h4 font-weight-bold pt-5 pb-4">
                    Retok Stick:
                  </h2>
                  <h3 className="h5 font-weight-bold">
                    Bastão para cobertura completa dos cabelos
                  </h3>
                  <p>
                    Desenvolvido para corrigir rapidamente os fios brancos. O
                    produto está disponível em formato bastão e pode ser
                    encontrado em cinco cores diferentes: preto, castanho,
                    castanho médio, loiro e acaju, atendendo todo o tipo de cor
                    de cabelo. Além disso, não resseca os fios, pois contém em
                    sua fórmula Cera de Carnaúba que oferece mais brilho e
                    maciez aos fios.
                  </p>
                  <h3 className="h5 font-weight-bold">Veja como usar:</h3>
                  <p>
                    Pressione o bastão do Retok Stick diretamente na área
                    desejada para obter uma cobertura completa. Para remover,
                    basta lavar com shampoo.
                  </p>
                </div>
                <div className="col d-flex  col-img-produto">
                  <img
                    className="border border-secondary my-auto"
                    src={imgs.loiro}
                    alt="Retok Stick"
                  />
                </div>
              </div>
              <div className="info-prod row px-5">
                <div className="col">
                  <h2 className="h4 font-weight-bold pt-5 pb-4">
                    Retok Líquido:
                  </h2>
                  <h3 className="h5 font-weight-bold">
                    Cobertura instantânea da raiz até as pontas
                  </h3>
                  <p>
                    O produto foi criado para a cobertura instantânea das
                    imperfeições do cabelo. O Retok líquido colore cabelos
                    brancos, permite o espaçamento de tinturas, seca rapidamente
                    e pode ser aplicado da raiz até as pontas do cabelo, com uma
                    cobertura completa e efeito luminoso nos fios. O produto
                    está disponível nas cores preto, castanho médio, castanho
                    escuro, loiro e acaju.
                  </p>
                  <h3 className="h5 font-weight-bold">Veja como usar:</h3>
                  <p>
                    Aplique o produto diretamente sobre a área desejada,
                    dividindo o cabelo em mechas ou aplicando na raiz. Deixe
                    secar por cinco minutos antes de pentear. Para remover,
                    basta lavar com shampoo.
                  </p>
                </div>
                <div className="col d-flex  col-img-produto">
                  <img
                    className="border border-secondary my-auto"
                    src={imgs.liquido}
                    alt="Retok Líquido"
                  />
                </div>
              </div>
            </div>
            <button type="button" className="seta-next my-auto" onClick={next}>
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
        </div>
      </Container>
      <BannerCatalogo />
      <LocalCatalogo />
      <Footer />
    </>
  );
}

export default Retok;
