import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Container } from './styles';
import LocalCatalogo from '~/components/LocalCatalogo';
import BannerCatalogo from '~/components/BannerCatalogo';
import Carousel from '~/components/Carousel';
import CarouselMobile from '~/components/CarouselMobile';
import Footer from '~/components/Footer';
import setaPrev from '~/assets/icons/seta-prev.gif';
import setaNext from '~/assets/icons/seta-next.gif';

function Home() {
  function nextInsta() {
    document.querySelector('.imgs-insta').scrollLeft += 312;
  }

  function prevInsta() {
    document.querySelector('.imgs-insta').scrollLeft -= 312;
  }

  function nextLinha() {
    document.querySelector('.imgs-linhas').scrollLeft += 304;
  }

  function prevLinha() {
    document.querySelector('.imgs-linhas').scrollLeft -= 304;
  }

  return (
    <>
      <Container>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Anaconda Cosméticos - Desde 1948</title>
          <meta
            name="description"
            content="Anaconda cosméticos conta com uma ampla linha de maquiagens, dermocosméticos e produtos para o corpo. Não testamos em animais."
          />
        </Helmet>
        <Carousel />
        <CarouselMobile />
        <LocalCatalogo />
        <div className="container px-5">
          <div className="row justify-content-center ">
            <div className="container">
              <div className="row d-block">
                <h3 className="mt-4 text-center font-weight-bold">
                  Está no INSTA!
                </h3>
                <h2 className=" text-center font-weight-bold">
                  @anaconda.cosmeticosoficial
                </h2>
              </div>

              <div className="d-flex">
                <button type="button" className="seta-prev" onClick={prevInsta}>
                  <img src={setaPrev} alt="Ícone seta anterior" />
                </button>
                <div className="row overflow-auto  flex-nowrap imgs-insta">
                  <div
                    className="col mr-4 my-3 p-0 insta justify-content-center  d-flex align-items-center"
                    id="insta1"
                  >
                    <a
                      href="https://www.instagram.com/anaconda.cosmeticosoficial/?hl=pt-br"
                      className="btn btn-insta"
                    >
                      TÁ no INSTA
                    </a>
                  </div>
                  <div
                    className="col mr-4 my-3 p-0 justify-content-center insta d-flex  align-items-center"
                    id="insta2"
                  >
                    <a
                      href="https://www.instagram.com/anaconda.cosmeticosoficial/?hl=pt-br"
                      className="btn btn-insta"
                    >
                      TÁ no INSTA
                    </a>
                  </div>

                  <div
                    className="col mr-4 my-3 p-0 justify-content-center insta d-flex  align-items-center"
                    id="insta3"
                  >
                    <a
                      href="https://www.instagram.com/anaconda.cosmeticosoficial/?hl=pt-br"
                      className="btn btn-insta"
                    >
                      TÁ no INSTA
                    </a>
                  </div>
                  <div
                    className="col mr-4 my-3 p-0  justify-content-center insta d-flex  align-items-center"
                    id="insta4"
                  >
                    <a
                      href="https://www.instagram.com/anaconda.cosmeticosoficial/?hl=pt-br"
                      className="btn btn-insta"
                    >
                      TÁ no INSTA
                    </a>
                  </div>
                </div>
                <button type="button" className="seta-next" onClick={nextInsta}>
                  <img src={setaNext} alt="Ícone seta próximo" />
                </button>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="container">
              <h3 className=" font-weight-bold lm mt-5">Veja as</h3>
              <h2 className=" font-weight-bold lm">LINHAS</h2>
              <div className="d-flex">
                <button type="button" className="seta-prev" onClick={prevLinha}>
                  <img src={setaPrev} alt="Ícone seta anterior" />
                </button>
                <div className="row overflow-auto flex-nowrap imgs-linhas">
                  <Link
                    to={`${process.env.PUBLIC_URL}/dermoglam`}
                    className="col mr-3 my-3 p-0 linha justify-content-center d-flex align-items-center"
                    id="linha1"
                  />
                  <Link
                    to={`${process.env.PUBLIC_URL}/biondina`}
                    className="col mr-3 my-3 p-0 justify-content-center linha d-flex  align-items-center"
                    id="linha2"
                  >
                    <p className="nome-linha m-0 text-uppercase">Biondina</p>
                  </Link>
                  <Link
                    to={`${process.env.PUBLIC_URL}/retok`}
                    className="col mr-3 my-3 p-0  justify-content-center linha d-flex  align-items-center"
                    id="linha4"
                  >
                    <p className="nome-linha m-0 w-50 text-uppercase">
                      Retok de cabelos
                    </p>
                  </Link>
                  <Link
                    to={`${process.env.PUBLIC_URL}/maquiagem`}
                    className="col mr-3 my-3 p-0  justify-content-center linha d-flex  align-items-center"
                    id="linha5"
                  >
                    <p className="nome-linha m-0 text-uppercase">Makeup</p>
                  </Link>
                  <Link
                    to={`${process.env.PUBLIC_URL}/clareador-facial`}
                    className="col mr-3 my-3 p-0  justify-content-center linha d-flex  align-items-center"
                    id="linha6"
                  >
                    <p className="nome-linha m-0  text-uppercase">Skin Care</p>
                  </Link>
                </div>
                <button type="button" className="seta-next" onClick={nextLinha}>
                  <img src={setaNext} alt="Ícone seta próximo" />
                </button>
              </div>
            </div>
          </div>

          <div className="container p-0">
            <h3 className=" font-weight-bold lm mt-5">Conheça os</h3>
            <h2 className=" font-weight-bold lm mb-4">PRODUTOS</h2>

            <div className="row mobile-block mb-3">
              <div className="col w-25 produtos p-0">
                <div
                  className="col mr-4 my-3 p-0 produto justify-content-center  d-flex align-items-center"
                  id="produto1"
                >
                  <Link
                    to={`${process.env.PUBLIC_URL}/dermoglam`}
                    className="btn btn-insta"
                  >
                    EU QUERO!
                  </Link>
                </div>
                <div className="m-4">
                  <h5 className="font-weight-bold">Booster Efeito Lifting</h5>
                  <h6 className="subdesc">Firmeza e Hidratação</h6>
                  <p className="produto-desc">
                    Reduz rugas, linhas de expressão <br />e é
                    anti-envelhecimento
                  </p>
                </div>
              </div>

              <div className="col w-25  produtos p-0 mx-3">
                <div
                  className="col mr-4 my-3 p-0 produto justify-content-center  d-flex align-items-center"
                  id="produto2"
                >
                  <Link
                    to={`${process.env.PUBLIC_URL}/dermoglam`}
                    className="btn btn-insta"
                  >
                    EU QUERO!
                  </Link>
                </div>
                <div className="m-4">
                  <h5 className="font-weight-bold">Sérum Antioxidante</h5>
                  <h6 className="subdesc">Vitamina C + vitamina E</h6>
                  <p className="produto-desc">
                    A fusão destas duas vitaminas potencializa os benefícios
                    para a saúde da sua pele
                  </p>
                </div>
              </div>

              <div className="col w-25  produtos p-0 ">
                <div
                  className="col mr-4 my-3 p-0 produto justify-content-center  d-flex align-items-center"
                  id="produto3"
                >
                  <Link
                    to={`${process.env.PUBLIC_URL}/dermoglam`}
                    className="btn btn-insta"
                  >
                    EU QUERO!
                  </Link>
                </div>
                <div className="m-4">
                  <h5 className="font-weight-bold">Sérum Facial</h5>
                  <h6 className="subdesc">Hidratante + Primer Iluminador</h6>
                  <p className="produto-desc">
                    Alto poder de hidratação por conter Ácido Hialurônico,
                    vitamina E e ativos hidratantes
                  </p>
                </div>
              </div>
            </div>

            <div className="row desk-only mb-3">
              <div className="col w-25 produtos p-0">
                <div
                  className="col mr-4 my-3 p-0 produto justify-content-center  d-flex align-items-center"
                  id="produto4"
                >
                  <Link
                    to={`${process.env.PUBLIC_URL}/clareador-facial`}
                    className="btn btn-insta"
                  >
                    EU QUERO!
                  </Link>
                </div>
                <div className="m-4">
                  <h5 className="font-weight-bold">Sabonete líquido facial</h5>
                  <h6 className="subdesc">Todos os tipos de pele</h6>
                  <p className="produto-desc">
                    Remove a oleosidade e resíduos de maquiagem
                  </p>
                </div>
              </div>

              <div className="col w-25  produtos p-0 mx-3">
                <div
                  className="col mr-4 my-3 p-0 produto justify-content-center  d-flex align-items-center"
                  id="produto5"
                >
                  <Link
                    to={`${process.env.PUBLIC_URL}/clareador-facial`}
                    className="btn btn-insta"
                  >
                    EU QUERO!
                  </Link>
                </div>
                <div className="m-4">
                  <h5 className="font-weight-bold">Gel esfoliante</h5>
                  <h6 className="subdesc">
                    Esferas naturais de nozes inglesas
                  </h6>
                  <p className="produto-desc">
                    Removem as células mortas, sem agredir a pele ou o meio
                    ambiente
                  </p>
                </div>
              </div>

              <div className="col w-25  produtos p-0 ">
                <div
                  className="col mr-4 my-3 p-0 produto justify-content-center  d-flex align-items-center"
                  id="produto6"
                >
                  <Link
                    to={`${process.env.PUBLIC_URL}/biondina`}
                    className="btn btn-insta"
                  >
                    EU QUERO!
                  </Link>
                </div>
                <div className="m-4">
                  <h5 className="font-weight-bold">
                    Biondina Loção clareadora
                  </h5>
                  <h6 className="subdesc">Reflexos naturais</h6>
                  <p className="produto-desc">
                    Reflexo natural que além de clarear os fios sem química,
                    deixa as suas madeixas lindas
                  </p>
                </div>
              </div>
            </div>
            <div className="row m-5 justify-content-center">
              <Link
                to={`${process.env.PUBLIC_URL}/produtos`}
                type="submit"
                className="btn"
              >
                VER MAIS
              </Link>
            </div>
          </div>
        </div>

        <BannerCatalogo />
        <Footer />
      </Container>
    </>
  );
}

export default Home;
