import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from './styles';
import { imgs } from '~/assets/Banner_desk_topo';
import setaPrev from '~/assets/icons/seta-prev.gif';
import setaNext from '~/assets/icons/seta-next.gif';

function Carousel() {
  let pos = 0;

  const slideInfinito = window.setInterval(function carrossel() {
    // Inicia a contagem de 7 segundos
    let currentPos = pos;
    const nextPos = pos + 1;
    if (
      nextPos <
      Array.from(document.querySelectorAll('.conheca')).length - 1
    ) {
      document.querySelectorAll('.conheca')[currentPos].classList.toggle('fadein');
      document.querySelectorAll('.conheca')[nextPos].classList.toggle('fadein');
      pos += 1;
      currentPos = pos;
      document.querySelector(
        '.banner-topo'
      ).style.backgroundImage = `url(${imgs[currentPos]})`;
    } else {
      document.querySelectorAll('.conheca')[currentPos].classList.toggle('fadein');
      pos = 0;
      currentPos = 0;
      document.querySelectorAll('.conheca')[currentPos].classList.toggle('fadein');
      document.querySelector(
        '.banner-topo'
      ).style.backgroundImage = `url(${imgs[currentPos]})`;
    }
  }, 7000);

  function nextBannerTopo() {
    let currentPos = pos;
    const nextPos = pos + 1;
    if (
      nextPos <
      Array.from(document.querySelectorAll('.conheca')).length - 1
    ) {
      document.querySelectorAll('.conheca')[currentPos].classList.toggle('fadein');
      document.querySelectorAll('.conheca')[nextPos].classList.toggle('fadein');
      pos += 1;
      currentPos = pos;
      document.querySelector(
        '.banner-topo'
      ).style.backgroundImage = `url(${imgs[currentPos]})`;
    } else {
      document.querySelectorAll('.conheca')[currentPos].classList.toggle('fadein');
      pos = 0;
      currentPos = 0;
      document.querySelectorAll('.conheca')[currentPos].classList.toggle('fadein');
      document.querySelector(
        '.banner-topo'
      ).style.backgroundImage = `url(${imgs[currentPos]})`;
    }
    setInterval(slideInfinito, 1000);
  }

  function prevBannerTopo() {
    if (pos - 1 >= 0) {
      document.querySelectorAll('.conheca')[pos].classList.toggle('fadein');
      document.querySelectorAll('.conheca')[pos - 1].classList.toggle('fadein');
      pos -= 1;
      document.querySelector(
        '.banner-topo'
      ).style.backgroundImage = `url(${imgs[pos]})`;
    } else {
      pos = Array.from(document.querySelectorAll('.conheca')).length - 2;
      document.querySelectorAll('.conheca')[0].classList.toggle('fadein');
      document.querySelectorAll('.conheca')[pos].classList.toggle('fadein');
      document.querySelector(
        '.banner-topo'
      ).style.backgroundImage = `url(${imgs[pos]})`;
    }
  }

  return (
    <>
      <Container>
        <div className="banner-topo d-flex align-items-center">
          <div className="container-fluid d-none conheca fadein position-relative h-100">
            <button
              type="button"
              className="seta-prev my-auto "
              onClick={prevBannerTopo}
            >
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>
            <div className="mx-auto px-5">
              <Link
                to={`${process.env.PUBLIC_URL}/maquiagem`}
                type="submit"
                className="btn-topo btn-new-banner pink position-absolute"
              >
                EU QUERO!
              </Link>
              <br />
            </div>
            <button
              type="button"
              className="seta-next my-auto"
              onClick={nextBannerTopo}
            >
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
          <div className="container-fluid d-none conheca position-relative h-100">
            <button
              type="button"
              className="seta-prev my-auto "
              onClick={prevBannerTopo}
            >
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>
            <div className="mx-auto px-5">
              <Link
                to={`${process.env.PUBLIC_URL}/dermoglam`}
                type="submit"
                className="btn-topo btn-new-banner gold position-absolute"
              >
                EU QUERO!
              </Link>
              <br />
            </div>
            <button
              type="button"
              className="seta-next my-auto"
              onClick={nextBannerTopo}
            >
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
          <div className="container-fluid d-none conheca">
            <button
              type="button"
              className="seta-prev my-auto "
              onClick={prevBannerTopo}
            >
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>
            <div className=" ml-5 pl-5 mr-auto" />
            <button
              type="button"
              className="seta-next my-auto"
              onClick={nextBannerTopo}
            >
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
          <div className="container-fluid d-none conheca">
            <button
              type="button"
              className="seta-prev my-auto "
              onClick={prevBannerTopo}
            >
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>
            <div className="ml-5 pl-5 mr-auto px-5">
              <h1 className="font-size-5">RETOK</h1>
              <p className="produto-topo-desc mb-5 legenda-banner">
                Retoque naturalmente
                <br />a cor dos seus cabelos.
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/retok`}
                type="submit"
                className="btn-topo"
              >
                EU QUERO!
              </Link>
              <br />
            </div>
            <button
              type="button"
              className="seta-next my-auto"
              onClick={nextBannerTopo}
            >
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
          <div className="container-fluid d-none conheca">
            <button
              type="button"
              className="seta-prev my-auto "
              onClick={prevBannerTopo}
            >
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>
            <div className="ml-5 pl-5 mr-auto px-5">
              <h1 className="font-size-6">BIONDINA SUNSHINE</h1>
              <p className="produto-topo-desc-2 mb-5 legenda-banner">
                Clareia e hidrata seus cabelos, sua fórmula <br />
                enriquecida com aloe vera e pantenol fortalece a <br />
                raiz e os fios dos cabelos, mantendo a umidade <br />
                natural dos fios e proporcionando hidratação <br />
                intensa, brilho e maciez.
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/biondina`}
                type="submit"
                className="btn-topo-2"
              >
                EU QUERO!
              </Link>
              <br />
            </div>
            <button
              type="button"
              className="seta-next my-auto"
              onClick={nextBannerTopo}
            >
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
          <div className="container-fluid d-none conheca">
            <button
              type="button"
              className="seta-prev my-auto "
              onClick={prevBannerTopo}
            >
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>
            <div className="ml-5 pl-5 mr-auto px-5">
              <h1 className="font-size-4 titulo3">CRISTAL GLOSS</h1>
              <p className="produto-topo-desc mb-5 legenda-banner">
                A fórmula com brilho e durabilidade <br />
                fantástica que atribui e mantém a <br />
                hidratação dos lábios, deixando-os perfeitos. <br />
                Confortável e fácil de aplicar, trazendo <br />
                luminosidade aos seus lábios.
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/maquiagem`}
                type="submit"
                className="btn-topo"
              >
                EU QUERO!
              </Link>
              <br />
            </div>
            <button
              type="button"
              className="seta-next my-auto"
              onClick={nextBannerTopo}
            >
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
          <div className="container-fluid d-none conheca">
            <button
              type="button"
              className="seta-prev my-auto "
              onClick={prevBannerTopo}
            >
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>
            <div className="ml-0 p-0 mr-auto px-5">
              <h1 className="font-size-7">CERA MODELADORA</h1>
              <p className="font-size-8">HAIR WAX STICK</p>
              <Link
                to={`${process.env.PUBLIC_URL}/#`}
                type="submit"
                className="btn-topo-3"
              >
                EU QUERO!
              </Link>
              <br />
            </div>
            <button
              type="button"
              className="seta-next my-auto"
              onClick={nextBannerTopo}
            >
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>

          {/*
          <div className="container d-none conheca">
            <button
              type="button"
              className="seta-prev my-auto "
              onClick={prevBannerTopo}
            >
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>
            <div className=" ml-5 pl-5 mr-auto">
              <h1 className="font-size-4">BIONDINA</h1>
              <p className="produto-topo-desc mb-5 legenda-banner">
                Clareia os fios e acentua a cor do loiro, <br />
                deixando as madeixas com mais brilho
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/biondina`}
                type="submit"
                className="btn-topo"
              >
                EU QUERO!
              </Link>
              <br />
              <img src={logo} alt="Logo Anaconda" className="logo mt-4" />
            </div>
            <button
              type="button"
              className="seta-next my-auto"
              onClick={nextBannerTopo}
            >
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
          <div className="container d-none conheca">
            <button
              type="button"
              className="seta-prev my-auto "
              onClick={prevBannerTopo}
            >
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>
            <div className=" ml-5 pl-5 mr-auto">
              <h2 className="font-size-4">PROFISSIONAL</h2>
              <p className="produto-topo-desc mb-5 legenda-banner">
                Conheça nossa linha de produtos profissionais
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/produtos-para-cabelo`}
                type="submit"
                className="btn-topo"
              >
                EU QUERO!
              </Link>
              <br />
              <img src={logo} alt="Logo Anaconda" className="logo mt-4" />
            </div>

            <button
              type="button"
              className="seta-next my-auto"
              onClick={nextBannerTopo}
            >
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
          <div className="container d-none conheca">
            <button
              type="button"
              className="seta-prev my-auto "
              onClick={prevBannerTopo}
            >
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>
            <div className=" ml-5 pl-5 mr-auto">
              <h2 className="font-size-4">RETOK</h2>
              <p className="produto-topo-desc mb-5 legenda-banner">
                Conheça nossa linha de <br />
                produtos para cabelos
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/retok`}
                type="submit"
                className="btn-topo"
              >
                EU QUERO!
              </Link>
              <br />
              <img src={logo} alt="Logo Anaconda" className="logo mt-4" />
            </div>

            <button
              type="button"
              className="seta-next my-auto"
              onClick={nextBannerTopo}
            >
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
          <div className="container d-none conheca">
            <button
              type="button"
              className="seta-prev my-auto "
              onClick={prevBannerTopo}
            >
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>
            <div className=" ml-5 pl-5 mr-auto">
              <h2 className="font-size-4">DermoGLAM</h2>
              <p className="produto-topo-desc mb-5 legenda-banner">
                Conheça nossa linha anti-age
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/dermoglam`}
                type="submit"
                className="btn-topo"
              >
                EU QUERO!
              </Link>
              <br />
              <img src={logo} alt="Logo Anaconda" className="logo mt-4" />
            </div>

            <button
              type="button"
              className="seta-next my-auto"
              onClick={nextBannerTopo}
            >
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
          <div className="container d-none conheca">
            <button
              type="button"
              className="seta-prev my-auto "
              onClick={prevBannerTopo}
            >
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>
            <div className=" ml-5 pl-5 mr-auto" id="makeup">
              <h2 className="font-size-4">MAKEUP</h2>
              <p className="produto-topo-desc mb-5 legenda-banner">
                Conheça nossa linha de maquiagem
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/maquiagem`}
                type="submit"
                className="btn-topo"
                id="btn-makeup"
              >
                EU QUERO!
              </Link>
              <br />
              <img src={logo} alt="Logo Anaconda" className="logo mt-4" />
            </div>

            <button
              type="button"
              className="seta-next my-auto"
              onClick={nextBannerTopo}
            >
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
          <div className="container d-none conheca ">
            <button
              type="button"
              className="seta-prev my-auto "
              onClick={prevBannerTopo}
            >
              <img src={setaPrev} alt="Ícone seta anterior" />
            </button>
            <div className=" ml-5 pl-5 mr-auto" id="skinCare">
              <h2 className="font-size-4">SKIN CARE</h2>
              <p className="produto-topo-desc mb-5 legenda-banner">
                Conheça nossa linha de <br />
                produtos para o rosto
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/clareador-facial`}
                type="submit"
                className="btn-topo"
                id="btn-skinCare"
              >
                EU QUERO!
              </Link>
              <br />
              <img src={logo} alt="Logo Anaconda" className="logo mt-4" />
            </div>



            <button
              type="button"
              className="seta-next my-auto"
              onClick={nextBannerTopo}
            >
              <img src={setaNext} alt="Ícone seta próximo" />
            </button>
          </div>
          */}
        </div>
      </Container>
    </>
  );
}

export default Carousel;
