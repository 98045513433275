import styled from 'styled-components';
import FundoBanner from '~/assets/banner_cat.png';

export const Container = styled.div`
  background-image: url(${FundoBanner});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff;
  h3 {
    font-size: 3.5rem;
    font-weight: 200;
  }
  a,
  a:hover,
  a:active {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    font-size: 0.8rem;
  }
  .conheca {
    font-size: 1.4rem;
    font-weight: 100;
  }

  .btn {
    border-radius: 0;
    border: 2px solid #fff;
    font-weight: bold;
    padding: 0.8rem 1.8rem;
    color: #fff;
    transition: all 0.8s ease;
  }
  .btn:hover {
    border-radius: 40px;
    background: #000;
    transition: all 0.8s ease;
    border: 2px solid #000;
  }
  .eu-quero {
    display: contents;
  }
  .baixar {
    font-size: 0.8rem;
  }

  @media (max-width: 768px) {
    .mobile-only {
      display: block !important;
    }
    .desk-only {
      display: none !important;
    }
    .mobile-logo {
      margin-top: -40px;
    }
    h3 {
      margin-right: 1rem !important;
      font-size: 3.2rem;
      text-align: right !important;
      margin: 0 !important;
      max-width: 360px;
    }
    .mx-4,
    .mr-4 {
      margin-right: 0 !important;
    }
    .mr-5 {
      margin-right: 1rem !important;
    }
    .mobile-row {
      justify-content: flex-end;
      display: flex;
    }
    .btn,
    .btn:hover {
      border-radius: 0;
      border: 2px solid #fff;
      font-weight: bold;
      padding: 0.6rem 1.6rem;
      color: #fff;
      font-size: 0.8rem;
    }
  }

  @media (max-width: 992px) {
    background-position: -206px;
    .mr-4 {
      margin-right: 0 !important;
    }
    .mr-5 {
      margin-right: 1rem !important;
    }
    h3 {
      font-size: 3rem;
    }
  }
  @media (max-width: 475px) {
    background-position: -206px;
    h3 {
      font-size: 2.3rem;
    }
  }
`;
