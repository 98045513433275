import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from './styles';
import { icons } from '~/assets/icons';
import PDF from '~/pages/Catalogo/catalogo.pdf';

function LocalCatalogo() {
  return (
    <Container className="fundo">
      <div className="container">
        <div className="row mobile-row">
          <Link
            to={`${process.env.PUBLIC_URL}/onde-encontrar`}
            className="col-md d-flex  w-fit-content py-4 justify-content-center"
          >
            <img src={icons.lupa} alt="Ícone Lupa" className="icon" />
            <div className="h-fit-content my-auto ">
              <h4>ONDE ENCONTRAR</h4>
              <p>
                Veja qual representante <br />
                está mais próximo de você
              </p>
            </div>
          </Link>
          <a
            href={PDF}
            className="col-md d-flex py-4  w-fit-content justify-content-center"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={icons.estrela}
              alt="Ícone Catálogo Virtual"
              className="icon"
            />
            <div className="h-fit-content my-auto">
              <h4>CATÁLOGO VIRTUAL</h4>
              <p>
                Todas nossas linhas de <br />
                produtos a um clique
              </p>
            </div>
          </a>
          <div className="col-md d-flex py-4 w-fit-content justify-content-center">
            <img src={icons.pin} alt="Ícone Pin" className="icon" />
            <div className="h-fit-content my-auto">
              <h4>SEJA UM REPRESENTANTE</h4>
              <p>
                Empresa 100% nacional com tecnologias <br />
                francesas, alemãs e americanas
              </p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default LocalCatalogo;
