import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  .fundo {
    background-color: #fff;
  }

  h4 {
    font-size: 0.8em;
    font-weight: 700;
  }

  p {
    font-size: 0.7em;
    margin: 0 !important;
  }
  a,
  a:visited,
  a:hover {
    color: #000;
    text-decoration: none;
  }
  .icon {
    width: 40px;
    height: 40px;
    margin: auto 10px auto 0;
  }
  @media (max-width: 768px) {
    .w-fit-content {
      width: fit-content;
    }
    .mobile-row {
      width: fit-content;
      display: block !important;
      margin: 0 auto;
    }
  }
`;
